// Core
import styled from 'styled-components';

// Layout
import { Row } from '../Row';

// Plugins
import { hexToRgba } from '../../../plugins/general';

export const Container = styled(Row)`
  padding: 1.25em 2.75em;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => `
    linear-gradient(to top, ${theme.color.paginationFooter.backgroundColor} 80%, transparent 20%);
  `};
  z-index: 5;
  flex-direction: column;
`;

export const Border = styled.div`
  margin-bottom: 0.9375em;
  height: 0;
  border: 1px solid ${({ theme }) => hexToRgba(theme.color.paginationFooter.borderColor, 0.3)};
`;

export const Content = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;
