// Libraries
import create from 'zustand';
import { persist } from 'zustand/middleware';

// Types
import { CustomerStats } from '../types/global';

export interface CustomersStatsDetails {
  id: string;
  stats: CustomerStats | null;
  lastFetched: number;
}

interface CustomersStatsStore {
  customersStats: CustomersStatsDetails[];
  setCustomerStats: (idCustomer: string, stats: CustomerStats) => void;
}

const useCustomersStatsStore = create(
  persist<CustomersStatsStore>(
    (set) => ({
      customersStats: [],
      setCustomerStats: (
        idCustomer: string,
        stats: CustomerStats,
      ) => set((state: CustomersStatsStore) => ({
        customersStats: state.customersStats.some(
          (item: CustomersStatsDetails) => item.id === idCustomer,
        )
          ? state.customersStats.map((item: CustomersStatsDetails) => (
            item.id === idCustomer
              ? { ...item, stats, lastFetched: Date.now() }
              : item))
          : [
            ...state.customersStats,
            { id: idCustomer, stats, lastFetched: Date.now() },
          ],
      })),
    }),
    {
      name: 'customers-stats-store',
      getStorage: () => localStorage,
    },
  ),
);

export default useCustomersStatsStore;
