// Core
import React from 'react';

// Component
import { ImagePreviewProps } from './ImagePreview.types';
import {
  Container,
  Element,
  ImageBlur,
  RemoveImage,
} from './ImagePreview.style';

// Layout
import { Col } from '../Col';
import { TrashCan } from '../../icons';

function ImagePreview({
  className,
  image,
  onRemove,
  testId,
}: ImagePreviewProps): JSX.Element {
  return (
    <Container
      className={className}
      testId={testId}
    >
      <Col>
        <Element src={image.source} alt={image.name} />
        <ImageBlur />
        <RemoveImage
          onClick={onRemove}
          type="link"
        >
          <TrashCan />
        </RemoveImage>
      </Col>
    </Container>
  );
}

export { ImagePreview };
