// Core
import styled from 'styled-components';

export const ColumnTableWithImage = styled.div`
  display: flex;
  align-items: center;
  word-wrap: anywhere;
`;

export const InputContainer = styled.div`
  width: 25%
`;

export const CreatedAtContainer = styled.div`
  min-width: 100px;
`;

export const ButtonContainer = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const BreadCrumbContainer = styled.div`
  padding: 16px 34px
`;

export const SelectCustomerContainer = styled.div`
  padding: 16px 44px;
`;

export const FilterBarContainer = styled.div`
  padding: 16px 44px;
`;

export const EmptyBoxContainer = styled.div`
  height: 100%;
  padding: 16px 48px 35px 48px;
`;

export const ActionBarContainer = styled.div`
  padding: 12px 44px;
  display: flex;
  justify-content: space-between;
`;

export const SelectContainer = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  padding-bottom: 30px;
`;
