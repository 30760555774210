// Core
import React from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Components
import { AlertLine } from '../../layout/icons';
import { Paragraph, ParagraphWeight } from '../../layout/elements/Paragraph';

// Component
import { DeleteWarningBoxProps } from './DeleteWarningBox.types';
import {
  DeletedItem,
  Element,
  Icon,
  TextsContainer,
  TitleContainer,
} from './DeleteWarningBox.style';

// Types
import { Theme } from '../../types/theme';

function DeleteWarningBox({
  className,
  itemIdentifier,
  itemType,
  testId,
}: DeleteWarningBoxProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  return (
    <Element
      data-testid={testId}
      className={className}
    >
      <TitleContainer>
        <Icon>
          <AlertLine />
        </Icon>
        <Paragraph weight={ParagraphWeight.bold} color={theme.color.deleteWarningBox.textColor}>
          {t('layout.elements.deleteWarningBox.title')}
        </Paragraph>
      </TitleContainer>
      <TextsContainer>
        <Paragraph color={theme.color.deleteWarningBox.textColor}>
          <Trans
            i18nKey="layout.elements.deleteWarningBox.deleteItemConfirmation"
            values={{
              itemType,
              identifier: itemIdentifier.key,
              deleteItem: itemIdentifier.value,
            }}
            components={{
              deleteItemValue: <DeletedItem />,
            }}
          />
        </Paragraph>
        <div>
          <Paragraph color={theme.color.deleteWarningBox.textColor}>
            {t('layout.elements.deleteWarningBox.irreversibleActionWarning', { itemType })}
          </Paragraph>
        </div>
      </TextsContainer>
    </Element>
  );
}

export { DeleteWarningBox };
