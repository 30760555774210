// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Layout
import { Drawer } from '../../../../layout/elements/Drawer';
import { ImagePreview } from '../../../../layout/elements/ImagePreview';
import { Input } from '../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import {
  Select,
  SelectSizeType,
  SelectTagsMode,
} from '../../../../layout/elements/Select';
import { AtLine } from '../../../../layout/icons';

// Media
import mockedCustomerLogo from '../../../../media/images/logoArcelorMittal.png';

// Views
import { CustomerUpdateProps } from './CustomersUpdate.types';
import { UpdateContainer } from './CustomersUpdate.style';

function UpdateCustomer({
  className,
  customer,
  isDrawerOpen,
  onCloseDrawer,
  onFieldChange,
  onUpdate,
  testId,
}: CustomerUpdateProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* Trigger onFieldChange based on fieldId */
  const handleFieldChange = (
    fieldId: string,
    value: string | string[],
  ): void => {
    onFieldChange(fieldId, value);
  };

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('common.edit')}`}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      primaryAction={{
        children: t('common.edit'),
        onClick: onUpdate,
        disabled: !customer.name || !customer.domains.length,
      }}
      secondaryAction={{ children: t('common.cancel'), onClick: onCloseDrawer }}
    >
      <UpdateContainer>
        <div>
          <ImagePreview
            image={{
              source: mockedCustomerLogo, // @TODO implement later
              name: customer.name,
            }}
            onRemove={() => {}} // @TODO: implement the onRemove logic later
            testId="customer-logo-preview"
          />
        </div>
        <div>
          <Paragraph
            testId="main-info-section"
            weight={ParagraphWeight.bold}
          >
            {t('common.mainInfo')}
          </Paragraph>
        </div>
        <div>
          <Input
            placeholder={`${t('common.name')}`}
            label={`${t('common.name')}`}
            value={customer.name}
            onChange={(newValue) => {
              if (typeof newValue === 'string') {
                handleFieldChange('name', newValue);
              }
            }}
            maxLength={65}
            testId="input-customer-name"
          />
        </div>
        <div>
          <Select
            label={`${t('view.customers.update.domain')}`}
            mode={SelectTagsMode.tags}
            placeholder={`${t('view.customers.update.placeholderDomain')}`}
            onChange={(value: string | string[]) => handleFieldChange('domains', value)}
            size={SelectSizeType.lg}
            value={customer.domains}
            supportingText={`${t('view.customers.update.domainsSupportingText')}`}
            prefix={<AtLine />}
          />
        </div>
      </UpdateContainer>
    </Drawer>
  );
}

export { UpdateCustomer };
