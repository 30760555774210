// Core
import styled from 'styled-components';

export const DeleteSection = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  
  strong {
    font-family: ${({ theme }) => theme.font.bold};
  };
`;
