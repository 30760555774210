// Core
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

// Libraries
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Layout
import { Information } from '../../layout/icons';
import { Paragraph } from '../../layout/elements/Paragraph';
import { Title, TitleSize } from '../../layout/elements/Title';
import { Tooltip, TooltipPlacement } from '../../layout/elements/Tooltip';

// Plugins
import { formatNumber } from '../../plugins/general';

// Types
import { Theme } from '../../types/theme';

// Component
import { DashboardIndicatorProps } from './DashboardIndicator.types';
import {
  Container,
  ContentContainer,
  DescriptionContainer,
  DescriptionContent,
  IconContainer,
  InfoContainer,
  InformationContainer,
  TitleContainer,
} from './DashboardIndicator.style';

function DashboardIndicator({
  className,
  critical = false,
  icon,
  testId,
  title,
  tooltipText,
  value = 0,
}: DashboardIndicatorProps): JSX.Element {
  const { i18n } = useTranslation();
  const theme: Theme = useTheme();

  const [isTooltipVisible, setIsToolTipVisible] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState<string>(formatNumber(value));

  const contentRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect((): void => {
    const minValue: number = 0;
    const maxValue: number = 999999999999;
    const validValue: number = !_.isNil(value) ? value : minValue;
    const limitedValue: number = Math.min(Math.max(validValue, minValue), maxValue);
    setFormattedValue(formatNumber(limitedValue));
  }, [value]);

  const checkVisibility = useCallback((): void => {
    const content: HTMLDivElement | null = contentRef.current;
    const container: HTMLDivElement | null = containerRef.current;

    if (content && container) {
      const contentWidth: number = content.scrollWidth;
      const containerMaxWidth: number = container.scrollWidth;
      if (contentWidth >= containerMaxWidth || 0) {
        setIsToolTipVisible(true);
      } else {
        setIsToolTipVisible(false);
      }
    }
  }, []);

  useEffect((): () => void => {
    const handleVisibilityCheck = (): void => {
      setTimeout(checkVisibility, 0);
    };
    handleVisibilityCheck();
    window.addEventListener('resize', handleVisibilityCheck);
    return (): void => {
      window.removeEventListener('resize', handleVisibilityCheck);
    };
  }, [checkVisibility, title]);

  useEffect((): void => {
    // Force update component when language changes
  }, [i18n.language]);

  return (
    <Container data-testid={testId} className={className}>
      <DescriptionContainer>
        <DescriptionContent critical={critical}>
          <IconContainer>
            {icon}
          </IconContainer>
          <TitleContainer ref={containerRef}>
            {
              isTooltipVisible
                ? (
                  <Tooltip text={title} placement={TooltipPlacement.top}>
                    <ContentContainer ref={contentRef}>
                      <Paragraph
                        color={
                          critical
                            ? theme.color.dashboardIndicator.activeTitleTextColor
                            : theme.color.dashboardIndicator.titleTextColor
                        }
                        preventOverflow
                      >
                        {title}
                      </Paragraph>
                    </ContentContainer>
                  </Tooltip>
                ) : (
                  <ContentContainer ref={contentRef}>
                    <Paragraph
                      color={
                        critical
                          ? theme.color.dashboardIndicator.activeTitleTextColor
                          : theme.color.dashboardIndicator.titleTextColor
                      }
                      preventOverflow
                    >
                      {title}
                    </Paragraph>
                  </ContentContainer>
                )
            }
          </TitleContainer>
        </DescriptionContent>
        <InfoContainer>
          <Tooltip text={tooltipText} placement={TooltipPlacement.left}>
            <InformationContainer>
              <Information />
            </InformationContainer>
          </Tooltip>
        </InfoContainer>
      </DescriptionContainer>
      <Title
        size={TitleSize.xs}
        color={
          critical
            ? theme.color.dashboardIndicator.activeValueTextColor
            : theme.color.dashboardIndicator.valueTextColor
        }
      >
        {formattedValue}
      </Title>
    </Container>
  );
}

export { DashboardIndicator };
