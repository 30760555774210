// Core
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.adminActions.containerBackgroundColor};
  border-radius: 8px;
  height: calc(50vh - 64px);
`;

export const Content = styled.div`
  padding: 16px 24px;
  border-bottom: solid 1px ${({ theme }) => theme.color.adminActions.borderColor};
  width: 100%;
  display: block;
`;

export const ResetPasswordContainer = styled.div`
  padding: 10px;
`;

export const AdminActionsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.adminActions.adminActionBackgroundColor};
  padding: 12px 12px 24px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: calc(100% - 64px);
`;
