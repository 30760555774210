// Core
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Layout = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 44px;
`;

export const InputContainer = styled.div`
  padding: 20px 0 12px 0;
  justify-content: space-between;
`;

export const PageHeaderContainer = styled.div`
  margin-bottom: 24px;
`;

export const CardCustomerContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding-bottom: 70px;
`;

export const IndicatorsPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25em, 1fr));
  gap: 1em;
`;

export const NewCustomerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterCustomersContainer = styled.div`
  width: 30%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
