// Core
import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 12.6667H14V14H2V12.6667ZM8.66667 3.88533V11.3333H7.33333V3.88533L3.286 7.93333L2.34333 6.99066L8 1.33333L13.6567 6.99L12.714 7.93266L8.66667 3.88666V3.88533Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
