// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { Col } from '../../../layout/elements/Col';
import { Paragraph, ParagraphWeight } from '../../../layout/elements/Paragraph';
import { Row } from '../../../layout/elements/Row';

// Component
import {
  AdminActionsContainer,
  Container,
  Content,
  ResetPasswordContainer,
} from './AdminActions.style';

// Admin Modules
import { ResetPassword } from './ResetPassword';

function AdminActions(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  return (
    <AdminPanel>
      <AdminActionsContainer>
        <Row gutter={8}>
          <Col cols={12}>
            <Container>
              <Content>
                <Paragraph weight={ParagraphWeight.bold}>
                  {`${t('view.adminActions.passwordReset.title')}`}
                </Paragraph>
              </Content>
              <ResetPasswordContainer>
                <ResetPassword />
              </ResetPasswordContainer>
            </Container>
          </Col>
        </Row>
      </AdminActionsContainer>
    </AdminPanel>
  );
}

export { AdminActions };
