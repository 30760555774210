// Core
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 10px;
`;

export const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 450px;
`;
