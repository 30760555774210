// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

// Libraries
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Plugins
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { RequestMethod, requestVantageApi } from '../../../plugins/request';
import { SocketDefaultListenEvents, getSocket, Socket } from '../../../plugins/socket';

// Locale
import i18n from '../../../locale/i18n';

// Store
import {
  Customer,
  User,
  getAuthToken,
  getCustomer,
  getUser,
} from '../../../store/session.store';

// Components
import { Alert, AlertType } from '../../../layout/elements/Alert';
import { AlertLine } from '../../../layout/icons';
import { BreadcrumbItem } from '../../../layout/elements/Breadcrumb';
import { Col } from '../../../layout/elements/Col';
import { CoordinatesType } from '../../../layout/elements/Map';
import { CustomerPanel } from '../../../layout/CustomerPanel';
import { Loader, LoaderSize } from '../../../layout/elements/Loader';
import { Row } from '../../../layout/elements/Row';
import { showToast, ToastType } from '../../../layout/elements/Toast';
import { ViewType } from '../../../components/CameraFeed';

// Crusher Modules
import { CSSettingsModule } from './CSSettingsModule';
import { CameraModule } from './CameraModule';
import { CavityLevelModule } from './CavityLevelModule';
import { DetailsModule } from './DetailsModule';
import { FeederInterlockModule } from './FeederInterlockModule';
import { HydraulicModule } from './HydraulicModule';
import { IndicatorsModule } from './IndicatorsModule';
import { LinerWearModule } from './LinerWearModule';
import { LubricationModule } from './LubricationModule';
import { MotorControlModule } from './MotorControlModule';
import { MotorStatusModule } from './MotorStatusModule';
import { VibrationModule } from './VibrationModule';

// Trendings
import { Trendings } from './Trendings';

// Types
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
  EquipmentCategory,
  EquipmentModel,
  Site,
  UserType,
} from '../../../types/global';
import {
  CrusherDashboardChartRange,
  CrusherWebsocketRequestEvents,
  CrusherWebsocketResponseEvents,
  EquipmentUnitCrusher,
  EquipmentUnitDataCrusher,
  TrendingType,
} from '../../../types/crusher';

// View
import {
  AlertContainer,
  CrusherMonitoringContainer,
  LeftMetricsContainer,
  LineWearCavityLevelContainer,
  LoaderContainer,
  RightMetricsContainer,
} from './CrusherDashboard.style';

// Drawers
import { HydraulicUnit, HydraulicUnitProps } from './Drawers/HydraulicUnit';
import { LubricationUnit, LubricationUnitProps } from './Drawers/LubricationUnit';
import { MotorControl, MotorControlProps } from './Drawers/MotorControl';
import { MotorStatus, MotorStatusProps } from './Drawers/MotorStatus';

function CrusherDashboard(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  ******************************************** TYPES ***********************************************
  *********************************************************************************************** */

  enum CrusherDrawer {
    hydraulic = 'hydraulic',
    lubrication = 'lubrication',
    motorControl = 'motorControl',
    motorStatus = 'motorStatus',
  }

  type State = {
    socket: Socket | null;
    dataRange: number;
    drawers: {
      isOpen: boolean;
      selectedDrawer: CrusherDrawer;
    };
    unitDataHistory: {
      data: EquipmentUnitDataCrusher[];
      isLoading: boolean;
      range: {
        start: number;
        end: number;
      },
    },
    operationStatusHistory: {
      data: EdgeDeviceOperationalStatusStatus[];
      isLoading: boolean;
      range: {
        start: number;
        end: number;
      },
    },
    trendings: {
      isOpen: boolean;
      selectedTrending: TrendingType;
    };
    equipmentModel: EquipmentModel | null;
    equipmentUnitCrusher: EquipmentUnitCrusher | null;
    isLoading: boolean;
    site: Site | null;
    selectView: ViewType;
  };

  /* ***********************************************************************************************
  **************************************** INITIAL STATE *******************************************
  *********************************************************************************************** */

  const initialState: State = {
    socket: null,
    dataRange: 1000 * 60 * 5,
    drawers: {
      isOpen: false,
      selectedDrawer: CrusherDrawer.hydraulic,
    },
    unitDataHistory: {
      data: [],
      isLoading: false,
      range: {
        start: dayjs().subtract(5, 'minute').valueOf(),
        end: Date.now(),
      },
    },
    operationStatusHistory: {
      data: [],
      isLoading: false,
      range: {
        start: dayjs().subtract(5, 'minute').valueOf(),
        end: Date.now(),
      },
    },
    trendings: {
      isOpen: false,
      selectedTrending: TrendingType.motorCurrent,
    },
    equipmentModel: null,
    equipmentUnitCrusher: null,
    isLoading: true,
    site: null,
    selectView: ViewType.camera,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  ******************************************* GETTERS **********************************************
  *********************************************************************************************** */

  const user: User = getUser() as User;
  const customer: Customer = getCustomer() as Customer;
  const authToken: string = getAuthToken() as string;
  const { id } = useParams() as { id: string };

  /* ***********************************************************************************************
  ******************************************* SOCKET ***********************************************
  *********************************************************************************************** */

  // @TODO: Apply week and month logic when backend is adjusted
  /* Maps the range type to interval in milliseconds */
  const getDataRange = (rangeType: CrusherDashboardChartRange): number => {
    switch (rangeType) {
      case CrusherDashboardChartRange.fiveMinutes:
        return 1000 * 60 * 5;
      case CrusherDashboardChartRange.week:
        return 1000 * 60 * 60 * 24 * 7;
      case CrusherDashboardChartRange.month:
        return 1000 * 60 * 60 * 24 * 30;
      default:
        return 1000 * 60 * 5;
    }
  };

  const requestCrusherData = useCallback((): void => {
    console.log('Emitting request event - crusher data');
    if (!state.socket) {
      throw new Error('Socket connection is not established');
    }
    state.socket.emit(CrusherWebsocketRequestEvents.realTimeCrusherData, {
      interval: 10000, // 10 seconds
      idEquipmentUnit: id,
      equipmentCategory: EquipmentCategory.crusher,
      idCustomer: user.type === UserType.admin && customer.id ? customer.id : null,
      dataRange: state.dataRange,
      authToken,
    });
  }, [
    id,
    user,
    customer,
    authToken,
    state.socket,
    state.dataRange,
  ]);

  // Handle the crusher data received from the socket
  const handleCrusherData = useCallback((
    data: Array<EquipmentUnitDataCrusher & EdgeDeviceOperationalStatusStatus>,
  ): void => {
    const equipmentUnitData: EquipmentUnitDataCrusher[] = data.filter((
      item: EquipmentUnitDataCrusher,
    ) => Object.keys(item).includes('unitStatus'));

    const operationalStatus: EdgeDeviceOperationalStatusStatus[] = data.filter((
      item: EdgeDeviceOperationalStatusStatus,
    ) => Object.keys(item).includes('edgeStatus'));

    setState((currentState: State) => ({
      ...currentState,
      unitDataHistory: {
        isLoading: false,
        data: [...currentState.unitDataHistory.data, ...equipmentUnitData],
        range: currentState.unitDataHistory.range,
      },
      operationStatusHistory: {
        isLoading: false,
        data: [...currentState.operationStatusHistory.data, ...operationalStatus],
        range: currentState.operationStatusHistory.range,
      },
    }));
  }, []);

  const listenToSocketEvents = useCallback((): void => {
    if (!state.socket) {
      throw new Error('listenToSocketEvents - socket connection is not established');
    }

    // Listen the connect event
    state.socket.on(SocketDefaultListenEvents.connect, () => {
      console.log('Socket connected');
      requestCrusherData();
    });

    // Listen the EquipmentUnitCrusherData event
    state.socket.on(
      CrusherWebsocketResponseEvents.equipmentUnitCrusherData,
      (data: Array<EquipmentUnitDataCrusher & EdgeDeviceOperationalStatusStatus>) => {
        console.log('Received socket data');
        handleCrusherData(data);
      },
    );

    // Listen to the disconnect event
    state.socket.on(SocketDefaultListenEvents.disconnect, () => {
      console.log('Socket disconnected');
    });

    console.log('Socket listeners registered');
  }, [handleCrusherData, requestCrusherData, state.socket]);

  /** Socket connection useEffect - initialize and connect */
  useEffect((): void => {
    console.log('Socket connection useEffect');

    if (!state.socket) {
      // Create socket connection
      const socket: Socket = getSocket({
        url: String(process.env.REACT_APP_EQUIPMENT_UNIT_DATA_MONITORING_API),
        token: authToken,
      });
      console.log('Socket instance created');

      try {
        // Start socket connection
        socket.connect();

        console.log('End of socket connection useEffect');
      } catch (error: unknown) {
        console.error('Error connecting to socket', error);
        showToast({ type: ToastType.error, text: `${i18n.t('common.defaultError')}` });
        setState((currentState: State) => ({
          ...currentState,
          isLoading: false,
        }));
        return;
      }

      // Set socket state
      setState((currentState: State) => ({
        ...currentState,
        socket,
        isLoading: false,
      }));
    } else {
      console.log('Socket instance already exists');
    }
  }, [
    authToken,
    listenToSocketEvents,
    requestCrusherData,
    state.socket,
  ]);

  // Clean up the socket connection when the component unmounts
  useEffect((): (() => void) => (): void => {
    if (state.socket) {
      console.log('Unmounting screen - disconnecting socket instance');
      state.socket.off(SocketDefaultListenEvents.connect);
      state.socket.off(CrusherWebsocketResponseEvents.equipmentUnitCrusherData);
      state.socket.disconnect();
      console.log('Screen unmounted');
    } else {
      console.log('Unmounting screen - no socket instance to disconnect');
    }
  }, [state.socket]);

  useEffect(() => {
    console.log('Socket listening useEffect');
    if (state.socket) {
      console.log('Socket listening useEffect - registering listeners');
      try {
        listenToSocketEvents();
      } catch (error: unknown) {
        console.error('Error registering listeners', error);
        showToast({ type: ToastType.error, text: `${i18n.t('common.defaultError')}` });
        setState((currentState: State) => ({
          ...currentState,
          isLoading: false,
        }));
      }
    }
  }, [listenToSocketEvents, state.socket]);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  const fetchPlantData = useCallback(
    async (): Promise<void> => {
      if (state.equipmentUnitCrusher) {
        const plantApiResponse: Site = await requestVantageApi.plant({
          method: RequestMethod.GET,
          path: `/site/${state.equipmentUnitCrusher.idSite}`,
          params: user.type === UserType.admin ? { idCustomer: customer.id } : {},
        });

        setState((currentState: State) => ({
          ...currentState,
          site: plantApiResponse,
        }));
      }
    },
    [customer, state.equipmentUnitCrusher, user],
  );

  const fetchEquipmentData = useCallback(
    async (): Promise<void> => {
      const equipmentUnitCrusher: EquipmentUnitCrusher = await requestVantageApi.equipmentUnit({
        method: RequestMethod.GET,
        path: `/crusher/${id}`,
        params: user.type === UserType.admin ? { idCustomer: customer.id } : {},
      });

      const equipmentModelRequest: Promise<EquipmentModel> = requestVantageApi.equipmentModel({
        method: RequestMethod.GET,
        path: `/crusher/${equipmentUnitCrusher.idModel}`,
        params: user.type === UserType.admin ? { idCustomer: customer.id } : {},
      });

      const equipmentUnitDataMonitoringRequest:
      Promise<EquipmentUnitDataCrusher> = requestVantageApi.equipmentUnitDataMonitoring({
        method: RequestMethod.GET,
        path: `/history/crusher/${id}`,
        params: user.type === UserType.admin
          ? {
            idCustomer: customer.id,
            startTimestamp: Date.now() - getDataRange(CrusherDashboardChartRange.fiveMinutes),
            // @TODO: apply the correct value after first release
            stopTimestamp: Date.now(),
          }
          : {
            startTimestamp: Date.now() - getDataRange(CrusherDashboardChartRange.fiveMinutes),
            stopTimestamp: Date.now(),
          },
      });

      const responses: [EquipmentModel, EquipmentUnitDataCrusher] = await Promise.all([
        equipmentModelRequest,
        equipmentUnitDataMonitoringRequest,
      ]);

      const [equipmentModelResponse] = responses;

      setState((currentState: State) => ({
        ...currentState,
        equipmentModel: equipmentModelResponse,
        equipmentUnitCrusher,
      }));
    },
    [customer, id, user],
  );

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  // Get the last unit data and operation status
  const lastUnitData: EquipmentUnitDataCrusher | null = _.last(state.unitDataHistory.data) || null;
  const lastOperationStatus: EdgeDeviceOperationalStatus | null = _.last(
    state.operationStatusHistory.data,
  )?.edgeStatus || null;

  // Memoized unit coordinates
  const unitCoordinates: CoordinatesType[] = useMemo(
    () => (state.site
      ? [{ lat: state.site.lat, lng: state.site.lng }]
      : []),
    [state.site],
  );

  // Handles the change of the selected trending
  const handleTrendingChange = useCallback((selectedTrending: MenuItemType): void => {
    const trendingName: TrendingType = selectedTrending.key as TrendingType;
    setState((currentState) => ({
      ...currentState,
      trendings: {
        ...currentState.trendings,
        selectedTrending: trendingName,
      },
    }));
  }, []);

  // Breadcrumb items
  const breadcrumbItems: BreadcrumbItem[] = [
    { text: t('common.plant'), target: '/plant-management' },
    { text: String(state.equipmentModel?.commercialName), target: `/crusher/${id}` },
  ];

  /**
   * Renders the appropriate drawer component based on the provided drawer type.
   *
   * @param {CrusherDrawer} drawer - The type of drawer to render.
   * @returns {JSX.Element | null} The drawer component if the drawer type is valid, otherwise null.
   */
  const renderCrusherDrawer = (drawer: CrusherDrawer): JSX.Element | null => {
    // Map of drawer types to their corresponding components
    const componentsMap: Record<CrusherDrawer, React.FC<
    HydraulicUnitProps | LubricationUnitProps | MotorControlProps | MotorStatusProps
    >> = {
      [CrusherDrawer.hydraulic]: HydraulicUnit,
      [CrusherDrawer.lubrication]: LubricationUnit,
      [CrusherDrawer.motorControl]: MotorControl,
      [CrusherDrawer.motorStatus]: MotorStatus,
    };

    // Get the component corresponding to the provided drawer type
    const DrawerComponent = componentsMap[drawer];

    // Return the drawer component with the necessary props if it exists, otherwise return null
    return DrawerComponent ? (
      <DrawerComponent
        onCloseDrawer={(): void => setState({
          ...state,
          drawers: { ...state.drawers, isOpen: false },
        })}
        isDrawerOpen={state.drawers.isOpen}
        operationalStatus={lastOperationStatus}
        unitData={lastUnitData}
      />
    ) : null;
  };

  // Fetch the plant data when the component mounts
  useEffect((): void => {
    if (!state.site) {
      fetchPlantData();
    }
  }, [fetchPlantData, state.site]);

  useEffect((): void => {
    fetchEquipmentData();
  }, [fetchEquipmentData]);

  return (
    <CustomerPanel>
      {
        (
          state.isLoading
          || _.isNil(state.equipmentModel)
          || _.isNil(state.equipmentUnitCrusher)
          || _.isNil(state.site)
        ) ? (
          <LoaderContainer>
            <Loader text={`${t('common.loader')}`} size={LoaderSize.xl} />
          </LoaderContainer>
          ) : (
            <CrusherMonitoringContainer>

              {lastOperationStatus !== EdgeDeviceOperationalStatus.on && (
                <Row>
                  <Col>
                    <AlertContainer>
                      <Alert
                        icon={<AlertLine />}
                        testId="cloud-off-alert"
                        title={`${t('layout.elements.drawer.alertText')}`}
                        className="drawer-header-alert"
                        closable={false}
                        type={AlertType.warning}
                      />
                    </AlertContainer>
                  </Col>
                </Row>
              )}

              <Row style={{ flex: 1 }} gutter={4}>
                <Col cols={5}>
                  <LeftMetricsContainer>
                    <DetailsModule
                      breadcrumbItems={breadcrumbItems}
                      unitData={lastUnitData}
                      operationalStatus={lastOperationStatus}
                      equipmentModel={state.equipmentModel}
                      unitCrusher={state.equipmentUnitCrusher}
                      testId="details-module"
                      openTrending={(): void => setState({
                        ...state,
                        trendings: {
                          ...state.trendings,
                          isOpen: true,
                        },
                      })}
                    />
                    <CSSettingsModule
                      testId="cs-settings-module"
                      unitData={lastUnitData}
                    />
                    {/* @TODO: After 1st release add onOpenDrawer func, when drawer is ready */}
                    <FeederInterlockModule
                      testId="feeder-automation-module"
                      onOpenDrawer={() => {}}
                    />
                    <MotorControlModule
                      testId="motor-control-module"
                      onOpenDrawer={(): void => {
                        setState({
                          ...state,
                          drawers: {
                            ...state.drawers,
                            isOpen: true,
                            selectedDrawer: CrusherDrawer.motorControl,
                          },
                        });
                      }}
                    />
                    <VibrationModule
                      testId="vibration-module"
                      unitData={lastUnitData}
                      onOpenDrawer={(): void => {
                        setState({
                          ...state,
                          drawers: {
                            ...state.drawers,
                            isOpen: true,
                            selectedDrawer: CrusherDrawer.motorStatus,
                          },
                        });
                      }}
                      operationalStatus={lastOperationStatus}
                    />
                  </LeftMetricsContainer>
                </Col>

                <Col cols={19}>
                  <RightMetricsContainer>
                    <Row gutter={8}>
                      <Col>
                        <IndicatorsModule
                          testId="indicators-module"
                          operationalStatus={lastOperationStatus}
                          unitData={lastUnitData}
                        />
                      </Col>
                    </Row>

                    <Row style={{ flex: 1 }} gutter={8}>
                      <Col cols={10}>
                        <HydraulicModule
                          unitData={lastUnitData}
                          operationalStatus={lastOperationStatus}
                          testId="hydraulic-module"
                          onOpenDrawer={(): void => {
                            setState({
                              ...state,
                              drawers: {
                                ...state.drawers,
                                isOpen: true,
                                selectedDrawer: CrusherDrawer.hydraulic,
                              },
                            });
                          }}
                        />
                      </Col>

                      <Col cols={10}>
                        <MotorStatusModule
                          testId="motor-status-module"
                          unitData={lastUnitData}
                          operationalStatus={lastOperationStatus}
                          onOpenDrawer={(): void => {
                            setState({
                              ...state,
                              drawers: {
                                ...state.drawers,
                                isOpen: true,
                                selectedDrawer: CrusherDrawer.motorStatus,
                              },
                            });
                          }}
                        />
                      </Col>

                      <Col cols={4}>
                        <LineWearCavityLevelContainer>
                          <LinerWearModule
                            unitData={lastUnitData}
                            decimalPlaces={2}
                          />
                          <CavityLevelModule
                            thresholds={{}}
                            unitData={lastUnitData}
                            operationalStatus={lastOperationStatus}
                          />
                        </LineWearCavityLevelContainer>
                      </Col>
                    </Row>

                    <Row style={{ flex: 1 }} gutter={8}>
                      <Col cols={12}>
                        <LubricationModule
                          unitData={lastUnitData}
                          operationalStatus={lastOperationStatus}
                          onOpenDrawer={(): void => {
                            setState({
                              ...state,
                              drawers: {
                                ...state.drawers,
                                isOpen: true,
                                selectedDrawer: CrusherDrawer.lubrication,
                              },
                            });
                          }}
                          testId="lubrication-module"
                        />
                      </Col>

                      <Col cols={12}>
                        <CameraModule
                          title={`${state.site?.name}`}
                          running={
                            lastOperationStatus === EdgeDeviceOperationalStatus.on
                          }
                          testId="camera-module"
                          pins={unitCoordinates}
                          // polygons={} // @TODO: implement the function after first release
                          centerCoordinates={unitCoordinates[0]}
                        />
                      </Col>
                    </Row>
                  </RightMetricsContainer>
                </Col>
              </Row>

              {state.trendings.isOpen && state.trendings.selectedTrending && (
              <Trendings
                testId="trendings"
                showTrendings={state.trendings.isOpen}
                selectedTrending={state.trendings.selectedTrending}
                selectedChartRange={CrusherDashboardChartRange.fiveMinutes}
                onChangeTrending={handleTrendingChange}
                onChangeChartRange={() => {}} // @TODO: implement the function after first release
                historyData={state.unitDataHistory.data}
                operationalStatus={state.operationStatusHistory.data}
                isLoading={state.unitDataHistory.isLoading}
                fetchHistory={() => {}} // @TODO: implement the function after first release
                onCloseTrendings={(): void => setState({
                  ...state,
                  trendings: { ...state.trendings, isOpen: false },
                })}
              />
              )}
              {renderCrusherDrawer(state.drawers.selectedDrawer)}
            </CrusherMonitoringContainer>
          )
      }
    </CustomerPanel>
  );
}

export { CrusherDashboard };
