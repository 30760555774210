// Core
import React, { useEffect } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

// Store
import { getUser, User } from '../../store/session.store';

// Types
import { UserType } from '../../types/global';

// Components
import { MainNavigation, NavigationContextType } from '../MainNavigation';

// Component
import { AdminPanelProps } from './AdminPanel.types';
import { Container, MainNavigationContainer } from './AdminPanel.style';

function AdminPanel({
  children,
  className,
  testId,
}: AdminPanelProps): JSX.Element {
  // Dependencies
  const navigate: NavigateFunction = useNavigate();

  // Get user
  const user: User = getUser() as User;

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  useEffect(() => {
    if (user.type !== UserType.admin) {
      navigate('/plant-management');
    }
  }, [user, navigate]);

  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <MainNavigationContainer>
        <MainNavigation testId="main-navigation" context={NavigationContextType.admin} />
      </MainNavigationContainer>
      {children}
    </Container>
  );
}

export { AdminPanel };
