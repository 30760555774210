// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Plugins
import { formatNumber } from '../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../plugins/request';

// Store
import useCustomersStatsStore, { CustomersStatsDetails } from '../../store/customersStats.store';

// Types
import { CustomerStats } from '../../types/global';
import { Theme } from '../../types/theme';

// Components
import {
  MapPin,
  Profile,
  Wifi,
} from '../../layout/icons';
import { OptionsDropdown } from '../../layout/elements/OptionsDropdown';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../layout/elements/Paragraph';
import { Tooltip } from '../../layout/elements/Tooltip';

// Component
import { CardCustomerProps } from './CardCustomer.types';
import {
  ActiveUsersContainer,
  CardCustomerComponent,
  ContentContainer,
  CustomerNameContainer,
  CustomerPreview,
  CustomerPreviewContainer,
  EquipmentUnitsContainer,
  MetricsContainer,
  OptionsDropdownContainer,
  SitesContainer,
} from './CardCustomer.style';
import { Loader, LoaderSize } from '../../layout/elements/Loader';

function CardCustomer({
  className,
  idCustomer,
  imgSrc,
  name,
  onClick,
  options,
  secondaryOptions,
  testId,
}: CardCustomerProps) {
  // Dependencies
  const theme: Theme = useTheme();
  const { t } = useTranslation();

  const { customersStats } = useCustomersStatsStore();

  // Refs
  const customerNameContentRef = useRef<HTMLDivElement | null>(null);
  const customerNameContainerRef = useRef<HTMLDivElement | null>(null);

  /* ***********************************************************************************************
  ******************************************* TYPES ************************************************
  * ********************************************************************************************* */

  interface CardCustomerState {
    isLoading: boolean;
    renderCustomerNameTooltip: boolean;
    stats: CustomerStats | null;
  }

  const blankStats: CustomerStats = {
    alarms: null,
    equipmentUnits: null,
    sites: null,
    users: null,
  };

  /* ***********************************************************************************************
  *************************************** INITIAL STATES *******************************************
  * ********************************************************************************************* */

  const initialState: CardCustomerState = {
    isLoading: false,
    renderCustomerNameTooltip: false,
    stats: blankStats,
  };

  const [state, setState] = useState(initialState);

  /* ***********************************************************************************************
  ****************************************** METHODS ***********************************************
  * ********************************************************************************************* */

  /* ********** Request **********  */

  /* Get the customer stats by idCustomer */
  const getStats = useCallback(async (): Promise<void> => {
    setState((prevState: CardCustomerState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const stats: CustomerStats = await requestVantageApi.customer({
        method: RequestMethod.GET,
        path: '/customer/stats',
        params: { idCustomer },
      });

      setState((prevState: CardCustomerState) => ({
        ...prevState,
        stats,
        isLoading: false,
      }));
    } catch (error: unknown) {
      setState((prevState: CardCustomerState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, [idCustomer]);

  /* Update the customer name tooltip render */
  const updateCustomerNameTooltipRender = useCallback((): void => {
    if (customerNameContentRef.current && customerNameContainerRef.current) {
      const customerNameContentWidth: number = customerNameContentRef.current.scrollWidth;
      const customerNameContainerWidth: number = parseFloat(
        getComputedStyle(customerNameContainerRef.current).width,
      );

      setState((prevState: CardCustomerState) => ({
        ...prevState,
        renderCustomerNameTooltip: customerNameContentWidth >= customerNameContainerWidth,
      }));
    }
  }, []);

  const renderCustomerName: JSX.Element = useMemo((): JSX.Element => (
    <CustomerNameContainer ref={customerNameContainerRef}>
      <ContentContainer ref={customerNameContentRef}>
        <Paragraph
          size={ParagraphSize.lg}
          weight={ParagraphWeight.bold}
          preventOverflow
        >
          {name}
        </Paragraph>
      </ContentContainer>
    </CustomerNameContainer>
  ), [name]);

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  * ********************************************************************************************* */

  useEffect((): () => void => {
    updateCustomerNameTooltipRender();
    window.addEventListener('resize', updateCustomerNameTooltipRender);
    return (): void => {
      window.removeEventListener('resize', updateCustomerNameTooltipRender);
    };
  }, [updateCustomerNameTooltipRender]);

  useEffect((): void => {
    getStats();
  }, [getStats]);

  useEffect((): void => {
    const oneDayMs: number = 24 * 60 * 60 * 1000;
    const customerData: CustomersStatsDetails | undefined = customersStats.find(
      (item: CustomersStatsDetails) => item.id === idCustomer,
    );

    const lastFetchedTime: number = customerData?.lastFetched || 0;

    if (!customerData || Date.now() - lastFetchedTime > oneDayMs) {
      getStats();
    } else {
      setState((prevState: CardCustomerState) => ({
        ...prevState,
        stats: customerData.stats,
      }));
    }
  }, [customersStats, getStats, idCustomer]);

  return (
    <CardCustomerComponent
      data-testid={testId}
      className={className}
      onClick={onClick}
    >
      <OptionsDropdownContainer onClick={(e) => e.preventDefault()}>
        <OptionsDropdown options={options} secondaryOptions={secondaryOptions} />
      </OptionsDropdownContainer>
      <CustomerPreviewContainer>
        <CustomerPreview alt={name} src={imgSrc} />
      </CustomerPreviewContainer>
      {
        state.renderCustomerNameTooltip
          ? <Tooltip text={name}>{renderCustomerName}</Tooltip>
          : renderCustomerName
      }
      <MetricsContainer>
        <Tooltip text={t('component.cardCustomer.equipmentUnitsTotal')}>
          <EquipmentUnitsContainer>
            <Wifi />
            {
              state.isLoading
                ? <Loader size={LoaderSize.xs} />
                : (
                  <Paragraph
                    size={ParagraphSize.sm}
                    color={theme.color.cardCustomer.metricsColor}
                    preventOverflow
                  >
                    {formatNumber(state.stats?.equipmentUnits)}
                  </Paragraph>
                )
            }
          </EquipmentUnitsContainer>
        </Tooltip>
        <Tooltip text={t('component.cardCustomer.sites')}>
          <SitesContainer>
            <MapPin />
            {
              state.isLoading
                ? <Loader size={LoaderSize.xs} />
                : (
                  <Paragraph
                    size={ParagraphSize.sm}
                    color={theme.color.cardCustomer.metricsColor}
                    preventOverflow
                  >
                    {formatNumber(state.stats?.sites)}
                  </Paragraph>
                )
            }
          </SitesContainer>
        </Tooltip>
        <Tooltip text={t('component.cardCustomer.activeUsers')}>
          <ActiveUsersContainer>
            <Profile />
            {
              state.isLoading
                ? <Loader size={LoaderSize.xs} />
                : (
                  <Paragraph
                    size={ParagraphSize.sm}
                    color={theme.color.cardCustomer.metricsColor}
                    preventOverflow
                  >
                    {formatNumber(state.stats?.users)}
                  </Paragraph>
                )
            }
          </ActiveUsersContainer>
        </Tooltip>
      </MetricsContainer>
    </CardCustomerComponent>
  );
}

export { CardCustomer };
