/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Plugins
import { hexToRgba } from '../../plugins/general';

// Component
import { DashboardIndicatorProps } from './DashboardIndicator.types';

export const Container = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.dashboardIndicator.backgroundColor};
  box-shadow: 0 4px 14px 0 ${({
    theme,
  }) => hexToRgba(theme.color.dashboardIndicator.boxShadowColor, 0.06)};
  padding: 18px 17px 24px 23px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  justify-content: center;
  
  > svg {
    color: ${({ theme }) => theme.color.dashboardIndicator.informationIconColor};
    width: 16px;
    height: 16px;
  };
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionContent = styled.div<Pick<DashboardIndicatorProps, 'critical'>>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  box-sizing: border-box;
  max-height: 26px;

  svg {
    color: ${({ theme, critical }) => (critical
      ? theme.color.dashboardIndicator.activeIconColor
      : theme.color.dashboardIndicator.iconColor
    )};
  };
`;

export const TitleContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

export const InfoContainer = styled.div`
  margin-left: auto;
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  
  > svg {
    width: 16px;
    height: 16px;
  };
`;

export const ContentContainer = styled.div`
  width: max-content;
  max-width: 100%;
`;
