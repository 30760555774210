// Core
import React from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';

// Components
import { DeleteWarningBox } from '../../../../components/DeleteWarningBox';
import { Col } from '../../../../layout/elements/Col';
import { Drawer } from '../../../../layout/elements/Drawer';
import { Input } from '../../../../layout/elements/Input';
import { Paragraph } from '../../../../layout/elements/Paragraph';
import { Row } from '../../../../layout/elements/Row';

// Views
import { CustomerDeleteProps } from './CustomersDelete.types';
import { DeleteSection } from './CustomersDelete.style';

function CustomersDelete({
  className,
  customer,
  deleteConfirmation,
  isDrawerOpen,
  onCloseDrawer,
  onDelete,
  testId,
}: CustomerDeleteProps) {
  // Dependencies
  const { t } = useTranslation();

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('view.customers.delete.drawerTitle')}`}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      primaryAction={{
        children: t('common.delete'),
        onClick: onDelete,
        disabled: deleteConfirmation.value !== t('common.delete').toUpperCase(),
      }}
      secondaryAction={{ children: t('common.cancel'), onClick: onCloseDrawer }}
    >
      <Row>
        <Col>
          <DeleteWarningBox
            itemIdentifier={{ key: 'id', value: customer.id }}
            itemType={`${t('common.customer').toLowerCase()}`}
            testId="delete-customer-warning"
          />
        </Col>
        <Col>
          <DeleteSection>
            <Paragraph>
              <Trans>
                {t('view.customers.delete.deleteSection')}
              </Trans>
            </Paragraph>
          </DeleteSection>
        </Col>
        <Col>
          <Input
            value={deleteConfirmation.value}
            onChange={deleteConfirmation.onChange}
            placeholder={`${t('view.customers.delete.deletePlaceholder')}`}
            label={`${t('common.delete').toUpperCase()}`}
            testId="delete-customer-confirmation"
          />
        </Col>
      </Row>
    </Drawer>
  );
}

export { CustomersDelete };
