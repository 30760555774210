// Core
import styled from 'styled-components';

export const Element = styled.div`
  position: absolute;
  z-index: 2;
`;

export const Container = styled.div`
  width: max-content;
`;
