// Core
import styled from 'styled-components';

// Libraries
import { Button } from 'antd';

// Layout
import { Row } from '../Row';

export const Element = styled.img`
  height: 200px;
  width: 100%;
  object-fit: contain;
  border: 1px solid ${({ theme }) => theme.color.imagePreview.borderColor};
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
`;

export const RemoveImage = styled(Button)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  visibility: hidden;
  color: ${({ theme }) => theme.color.imagePreview.removeIconColor};
    
  &:hover {
    color: ${({ theme }) => theme.color.imagePreview.removeIconHoverColor} !important;
  };
`;

export const ImageBlur = styled.div`
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1;
  transition: all 0.4s ease-in-out;
  border-radius: 4px;
`;

export const Container = styled(Row)`
  height: 14.286em;
  
  &:hover {
    ${RemoveImage} {
      visibility: visible;
    };

    ${ImageBlur} {
      background-color: ${({ theme }) => theme.color.imagePreview.imageBlurBackgroundColor};
      opacity: 0.5;
      backdrop-filter: blur(7px);
    };
  };
`;
