// Core
import styled from 'styled-components';

export const Element = styled.div`
  background: ${({ theme }) => theme.color.deleteWarningBox.backgroundColor};
  border: 1px solid  ${({ theme }) => theme.color.deleteWarningBox.borderColor};
  border-radius: 4px;
  padding: 12px 20px 12px 16px;
  flex-direction: column;
  gap: 6px;
  
  strong {
    font-family: ${({ theme }) => theme.font.bold};
  };
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const TextsContainer = styled.div`
  gap: 6px;
  align-items: center;
  padding-left: 32px;
`;

export const DeletedItem = styled.span`
  color: ${({ theme }) => theme.color.deleteWarningBox.deleteItemTextColor};
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 12px;
  
  > svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.color.deleteWarningBox.containerIconColor};
  };
`;
