// Core
import React, {
  useRef,
  useState,
} from 'react';

// Layout
import {
  Button,
  ButtonSize,
  ButtonType,
} from '../Button';
import { OptionsSelect, OptionsSelectMode } from '../OptionsSelect';
import { LayoutColumn } from '../../icons';

// Hooks
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

// Component
import { TableColumnLayoutProps } from './TableColumnLayout.types';
import { Container, Element } from './TableColumnLayout.style';

function TableColumnLayout({
  className,
  onSelect,
  options,
  selected,
  testId,
}: TableColumnLayoutProps): JSX.Element {
  // Ref
  const dropdownRef = useRef(null);

  /* **********************************************************************************************
  ***************************************** LOCAL STATES ******************************************
  ********************************************************************************************** */

  const [isOpen, setIsOpen] = useState<boolean>(false);

  /* **********************************************************************************************
  ******************************************* METHODS *********************************************
  ********************************************************************************************** */

  // Hook to handle clicks outside the dropdown
  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  return (
    <Container
      ref={dropdownRef}
      data-testid={testId}
      className={className}
    >
      <Button
        block
        onClick={() => setIsOpen((prevState) => !prevState)}
        size={ButtonSize.xs}
        square
        type={ButtonType.secondary}
      >
        <LayoutColumn />
      </Button>
      <Element>
        <OptionsSelect
          testId="options-select-container"
          options={options}
          onSelect={onSelect}
          open={isOpen}
          onClose={() => setIsOpen(isOpen)}
          selected={selected}
          mode={OptionsSelectMode.multiple}
        />
      </Element>
    </Container>
  );
}

export { TableColumnLayout };
