// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.334 4.00016H14.6673V5.3335H13.334V14.0002C13.334 14.177 13.2637 14.3465 13.1387 14.4716C13.0137 14.5966 12.8441 14.6668 12.6673 14.6668H3.33398C3.15717 14.6668 2.9876 14.5966 2.86258 14.4716C2.73756 14.3465 2.66732 14.177 2.66732 14.0002V5.3335H1.33398V4.00016H4.66732V2.00016C4.66732 1.82335 4.73756 1.65378 4.86258 1.52876C4.9876 1.40373 5.15717 1.3335 5.33398 1.3335H10.6673C10.8441 1.3335 11.0137 1.40373 11.1387 1.52876C11.2637 1.65378 11.334 1.82335 11.334 2.00016V4.00016ZM12.0007 5.3335H4.00065V13.3335H12.0007V5.3335ZM8.94332 9.3335L10.122 10.5122L9.17932 11.4548L8.00065 10.2762L6.82198 11.4548L5.87932 10.5122L7.05798 9.3335L5.87932 8.15483L6.82198 7.21216L8.00065 8.39083L9.17932 7.21216L10.122 8.15483L8.94332 9.3335ZM6.00065 2.66683V4.00016H10.0007V2.66683H6.00065Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
