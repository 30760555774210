// Core
import styled from 'styled-components';

const sharedStylesFlex: string = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  /* ******************* FLEX ******************* */
  ${sharedStylesFlex};
  
  justify-content: unset;
  flex-wrap: wrap;
  width: max-content;
  gap: 8px;
`;

export const Button = styled.button<{ selected: boolean }>`
  /* ******************* FLEX ******************* */
  ${sharedStylesFlex};
  
  /* ******************* BORDER ******************* */
  border: 1px solid ${({ theme, selected }) => (
    selected
      ? theme.color.filterBar.selectedBorderColor
      : theme.color.filterBar.borderColor
  )};
  
  /* ******************* OPACITY ******************* */
  opacity: ${({ selected }) => (
    selected
      ? 'unset'
      : '0.6'
  )};
  
  /* ******************* BACKGROUND COLOR ******************* */
  background-color: ${({ theme }) => theme.color.filterBar.backgroundColor};

  width: max-content;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  /* ******************* HOVER ******************* */
  &:hover {
    opacity: unset;
  };
`;

export const Image = styled.img`
  /* ******************* FLEX ******************* */
  ${sharedStylesFlex};
  
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

export const InfoContainer = styled.div`
  /* ******************* FLEX ******************* */
  ${sharedStylesFlex};
  
  padding: 0 10px;
  height: 48px;
`;

export const TitleContainer = styled.div`
  padding-right: 8px;
`;
