// Core
import styled from 'styled-components';

export const CustomerOverview = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.color.customersScreen.customerOverviewBackgroundColor};
  gap: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
`;

export const CustomerOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomerInfoContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const OverviewTextContainer = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
`;
