// Core
import React from 'react';

export interface Items {
  id: string;
  text: string;
}

export enum SelectSizeType {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum SelectSizeTypeAntD {
  small = 'small',
  middle = 'middle',
  large = 'large',
}

export enum SelectTagsMode {
  tags = 'tags',
}

export interface SelectProps {
  allowClear?: boolean;
  className?: string;
  disabled?: boolean;
  items?: Items[];
  label: string;
  mode?: SelectTagsMode;
  onChange?: (value: string | string[]) => void;
  placeholder: string;
  prefix?: React.ReactNode;
  size?: SelectSizeType;
  supportingText?: string;
  testId?: string;
  value?: string | string[];
}
