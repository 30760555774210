// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Components
import { Col } from '../../../../layout/elements/Col';
import { Drawer } from '../../../../layout/elements/Drawer';
import { Input } from '../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import { Row } from '../../../../layout/elements/Row';
import {
  Select,
  SelectSizeType,
  SelectTagsMode,
} from '../../../../layout/elements/Select';
import { AtLine } from '../../../../layout/icons';

// Views
import { CustomerCreateProps } from './CustomersCreate.types';
import { InputContainer } from './CustomersCreate.style';

function CustomersCreate({
  className,
  customer,
  isDrawerOpen,
  onCloseDrawer,
  onCreate,
  onFieldChange,
  testId,
}: CustomerCreateProps) {
  // Dependencies
  const { t } = useTranslation();

  // Trigger onFieldChange based on fieldId
  const handleFieldChange = (
    fieldId: string,
    value: string | string[],
  ): void => {
    onFieldChange(fieldId, value);
  };

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.customers.newCustomer')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      primaryAction={{
        children: t('common.create'),
        onClick: onCreate,
        disabled: !customer.name || !(customer.domains && customer.domains.length),
      }}
      secondaryAction={{ children: t('common.cancel'), onClick: onCloseDrawer }}
    >

      <Row style={{ flex: 1 }}>
        <Col>
          {/* @TODO: uncomment this in the future */}
          {/* <LogoContainer> */}
          {/*  <Paragraph */}
          {/*    weight={ParagraphWeight.bold} */}
          {/*    testId="customer-logo-section" */}
          {/*  > */}
          {/*    Customer Logo */}
          {/*  </Paragraph> */}
          {/* </LogoContainer> */}

          {/* @TODO: uncomment this in the future */}
          {/* <FileUploadContainer> */}
          {/*  <FileUpload */}
          {/*    onUpload={() => { */}
          {/*    }} */}
          {/*    file={blankFile} */}
          {/*    id="customer-logo" */}
          {/*    onRemove={() => { */}
          {/*    }} */}
          {/*    supportingText=".jpg .png .gif .jpeg (Max. 1MB)" */}
          {/*    accept=".jpg, .png, .gif, .jpeg" */}
          {/*  /> */}
          {/* </FileUploadContainer> */}

          {/* @TODO: uncomment this in the future, apply the logic and translate */}
          {/* <CoverContainer> */}
          {/*  <Paragraph */}
          {/*    weight={ParagraphWeight.bold} */}
          {/*    testId="customer-cover-section" */}
          {/*  > */}
          {/*    Customer Cover */}
          {/*  </Paragraph> */}
          {/* </CoverContainer> */}

          {/* <ActionsUploadContainer> */}
          {/*  <Button */}
          {/*    type={ButtonType.secondary} */}
          {/*    color={ButtonColor.primary} */}
          {/*    size={ButtonSize.xs} */}
          {/*    onClick={() => {}} */}
          {/*  > */}
          {/*    Gallery */}
          {/*  </Button> */}
          {/*  <Button */}
          {/*    type={ButtonType.secondary} */}
          {/*    color={ButtonColor.primary} */}
          {/*    size={ButtonSize.xs} */}
          {/*    onClick={() => {}} */}
          {/*  > */}
          {/*    Upload */}
          {/*  </Button> */}
          {/* </ActionsUploadContainer> */}

          {/* <FileUploadContainer> */}
          {/*  <FileUpload */}
          {/*    onUpload={() => { */}
          {/*    }} */}
          {/*    file={blankFile} */}
          {/*    id="customer-cover" */}
          {/*    onRemove={() => {}} */}
          {/*    supportingText=".jpg .png .gif .jpeg (Max. 1MB)" */}
          {/*    accept=".jpg, .png, .gif, .jpeg" */}
          {/*  /> */}
          {/* </FileUploadContainer> */}

          <div>
            <Paragraph
              weight={ParagraphWeight.bold}
              testId="main-info-section"
            >
              {t('common.mainInfo')}
            </Paragraph>
          </div>

          <InputContainer>
            <Input
              placeholder={`${t('common.name')}`}
              label={`${t('common.name')}`}
              value={customer.name}
              onChange={(newValue) => {
                if (typeof newValue === 'string') {
                  handleFieldChange('name', newValue);
                }
              }}
              maxLength={65}
              testId="input-customer-name"
            />
          </InputContainer>

          <div>
            <Select
              label={`${t('view.customers.create.domain')}`}
              mode={SelectTagsMode.tags}
              placeholder={`${t('view.customers.create.placeholderDomain')}`}
              onChange={(value: string | string[]) => handleFieldChange('domains', value)}
              size={SelectSizeType.lg}
              value={customer.domains}
              supportingText={`${t('view.customers.create.domainsSupportingText')}`}
              prefix={<AtLine />}
            />
          </div>

          {/* @TODO: apply in the future */}
          {/* <TechnicalInfoContainer> */}
          {/*  <Paragraph */}
          {/*    weight={ParagraphWeight.bold} */}
          {/*  > */}
          {/*    Technical Info */}
          {/*  </Paragraph> */}
          {/* </TechnicalInfoContainer> */}

          {/* <OCPServerContainer> */}
          {/*  <Switch */}
          {/*    size={SwitchWithTextSize.md} */}
          {/*    onChange={() => {}} */}
          {/*    text="OCP Server" */}
          {/*  /> */}
          {/* </OCPServerContainer> */}
        </Col>
      </Row>
    </Drawer>
  );
}

export { CustomersCreate };
