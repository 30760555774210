// Libraries
import create, { StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';

export interface TableColumnConfigs {
  id: string; // Column id
  visible: boolean; // Column visibility
}

export interface TableConfigs {
  id: string; // Table id
  columns: TableColumnConfigs[]; // Table columns
}

export type TablesConfigs = Record<string, TableConfigs>; // Record<tableId, TableConfigs>

export interface LayoutState {
  tablesConfigs: TablesConfigs;
}

export const useLayoutStore = create(
  persist<LayoutState>(
    (set: StoreApi<LayoutState>['setState']) => ({
      tablesConfigs: {},
      setTableConfigs: (tableConfigs: TableConfigs): void => {
        set((state: LayoutState) => ({
          tablesConfigs: {
            ...state.tablesConfigs,
            [tableConfigs.id]: tableConfigs,
          },
        }));
      },
      getTableConfigs: (tableId: string) => (
        state: LayoutState,
      ) => state.tablesConfigs[tableId],
    }),
    {
      name: 'layout-store',
      getStorage: () => localStorage,
    },
  ),
);

// Getters
export const getTableConfigs = (tableId: string): TableConfigs | undefined => {
  const state: LayoutState = useLayoutStore.getState();
  return state.tablesConfigs[tableId];
};

// Setters
export const setTableConfigs = (tableConfigs: TableConfigs): void => {
  useLayoutStore.setState((state: LayoutState) => ({
    tablesConfigs: {
      ...state.tablesConfigs,
      [tableConfigs.id]: tableConfigs,
    },
  }));
};
