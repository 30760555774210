// Core
import styled from 'styled-components';

export const LogoContainer = styled.div`
  padding-bottom: 20px;
`;

export const FileUploadContainer = styled.div`
  width: 100%;
  height: 86px;
`;

export const CoverContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 24px;
`;

export const ActionsUploadContainer = styled.div`
  display: flex;
  max-width: max-content;
  padding-bottom: 20px;
  gap: 10px;
  padding-top: 20px;
`;

export const InputContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 8px;
`;

export const TechnicalInfoContainer = styled.div`
  padding-top: 24px;
`;

export const OCPServerContainer = styled.div`
  padding-top: 20px;
`;
