// Types
import { EquipmentUnit } from '../../../types/global';

export enum DrawerOperation {
  create = 'create',
  delete = 'delete',
  read = 'read',
  update = 'update',
}

export enum ColumnId {
  checkbox = 'checkbox',
  commercialName = 'commercialName',
  createdAt = 'createdAt',
  customer = 'customer',
  customerPartNumber = 'customerPartNumber',
  dropdown = 'dropdown',
  equipmentCategory = 'equipmentCategory',
  installStatus = 'installStatus',
  serialNumber = 'serialNumber',
  superiorPartNumber = 'superiorPartNumber',
}

export interface InfoEquipmentCategory {
  imgSrc?: string;
  key: string;
  count: number;
  title: string;
  id: string;
}

export interface CombinedEquipmentsAndCustomer extends EquipmentUnit {
  commercialName: string | null;
  superiorPartNumber: string | null;
  customer: string | null;
  key?: string;
}
