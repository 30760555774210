// Core
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

// Libraries
import { Select as SelectAntD } from 'antd';
import { useTheme } from 'styled-components';

// Layout
import { Paragraph, ParagraphSize } from '../Paragraph';
import { CaretDown, Close } from '../../icons';

// Component
import {
  Items,
  SelectProps,
  SelectSizeType,
  SelectSizeTypeAntD,
  SelectTagsMode,
} from './Select.types';
import {
  Container,
  FloatingLabel,
  PrefixContainer,
  SupportingTextContainer,
} from './Select.style';

// Types
import { Theme } from '../../../types/theme';

function Select({
  allowClear = true,
  className,
  disabled = false,
  items,
  label,
  mode,
  onChange,
  placeholder,
  prefix,
  size = SelectSizeType.md,
  supportingText,
  testId,
  value,
}: SelectProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  // Refs
  const floatingLabelRef: React.RefObject<HTMLLabelElement> = useRef<HTMLLabelElement>(null);
  const containerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  /* **********************************************************************************************
  ***************************************** LOCAL STATES ******************************************
  ********************************************************************************************** */

  /* Initial state */
  const initialState = {
    isHovered: false,
    isOpen: false,
    hoverTimeout: null as NodeJS.Timeout | null,
    showFloatingLabel: true,
  };

  const [state, setState] = useState(initialState);

  const convertSizeToAntD = (selectSize: SelectSizeType): SelectSizeTypeAntD => {
    const mapSize: Record<SelectSizeType, SelectSizeTypeAntD> = {
      [SelectSizeType.sm]: SelectSizeTypeAntD.small,
      [SelectSizeType.md]: SelectSizeTypeAntD.middle,
      [SelectSizeType.lg]: SelectSizeTypeAntD.large,
    };

    return mapSize[selectSize];
  };

  const options: { label: string, value: string }[] | undefined = items?.map((item: Items) => ({
    label: item.text,
    value: item.id,
  }));

  // Function to position the floating label
  const positionLabel = (): void => {
    if (floatingLabelRef.current && containerRef.current) {
      const selectElement: Element | null = containerRef.current.querySelector('.ant-select');
      const labelElement: HTMLLabelElement = floatingLabelRef.current;

      if (selectElement) {
        const selectRect: DOMRect = selectElement.getBoundingClientRect();
        const containerRect: DOMRect = containerRef.current.getBoundingClientRect();

        labelElement.style.left = `${selectRect.left - containerRect.left + 13}px`;
      }
    }
  };

  useEffect(
    (): void | (() => void) => {
      positionLabel();
      window.addEventListener('resize', positionLabel);

      return () => {
        window.removeEventListener('resize', positionLabel);
      };
    },
    [
      value,
      mode,
      label,
      prefix,
      size,
      state.isOpen,
    ],
  );

  useEffect((): void => {
    positionLabel();
  }, [state.showFloatingLabel]);

  // Effect to handle floating label visibility based on value and mode
  useEffect((): () => void => {
    let timeout: NodeJS.Timeout;

    if (
      (mode !== SelectTagsMode.tags && value && value !== '')
      && (!Array.isArray(value) || value.length > 0)
    ) {
      setState((prevState) => ({ ...prevState, showFloatingLabel: true }));
    } else if (mode === SelectTagsMode.tags || !value) {
      timeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, showFloatingLabel: false }));
      }, 250);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value, mode]);

  // Function to set the border radius of the select element
  useEffect((): void => {
    const selectElement = containerRef.current?.querySelector('.ant-select') as HTMLElement;

    if (selectElement) {
      selectElement.style.borderRadius = prefix ? '0 4px 4px 0' : '4px';
    }
  }, [prefix]);

  // Function to determine the placeholder content
  const getPlaceholderContent = (): JSX.Element | string => {
    let result: string;

    if (disabled) {
      result = label;
    } else if (mode === SelectTagsMode.tags || (state.isHovered && !value) || state.isOpen) {
      result = placeholder;
    } else {
      result = label;
    }

    if (mode === SelectTagsMode.tags) {
      return (
        <div className={`placeholder-text ${state.isHovered ? 'hovered' : 'inherit'}`}>
          {result}
        </div>
      );
    }
    return state.isHovered ? placeholder : label;
  };

  const getSupportingTextColor = (): string => {
    let color: string;

    if (disabled) {
      color = theme.color.select.supportingTextColorDisabled;
    } else if (state.isHovered) {
      color = theme.color.select.supportingTextTextColorHover;
    } else {
      color = theme.color.select.supportingTextTextColor;
    }

    return color;
  };

  const handleMouseEnter = (): void => {
    if (!disabled) {
      if (state.hoverTimeout) clearTimeout(state.hoverTimeout);
      const timeout: NodeJS.Timeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, isHovered: true }));
      }, 150);
      setState((prevState) => ({ ...prevState, hoverTimeout: timeout }));
    }
  };

  const handleMouseLeave = (): void => {
    if (!disabled) {
      if (state.hoverTimeout) clearTimeout(state.hoverTimeout);
      const timeout: NodeJS.Timeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, isHovered: false }));
      }, 250);
      setState((prevState) => ({ ...prevState, hoverTimeout: timeout }));
    }
  };

  // Adjust value only if mode requires an array and value is not an array
  let convertedValue: string | string[] | undefined = value;
  if ((mode === SelectTagsMode.tags) && !Array.isArray(value)) {
    convertedValue = value ? [value] : [];
  }

  return (
    <div data-testid={testId}>
      <Container
        className={className}
        disabled={disabled}
        label={label}
        ref={containerRef}
        size={size}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          {prefix && (
            <PrefixContainer disabled={disabled}>
              {prefix}
            </PrefixContainer>
          )}
        </div>
        <SelectAntD
          allowClear={allowClear && { clearIcon: <Close /> }}
          bordered={false}
          disabled={disabled}
          maxTagCount={mode === SelectTagsMode.tags ? 'responsive' : undefined}
          mode={mode}
          onChange={onChange}
          onDropdownVisibleChange={(open) => setState((prevState) => ({
            ...prevState,
            isOpen: open,
          }))}
          options={options}
          placeholder={getPlaceholderContent()}
          size={convertSizeToAntD(size)}
          value={convertedValue}
          suffixIcon={<CaretDown />}
        />
        {size !== SelectSizeType.sm && mode !== SelectTagsMode.tags && state.showFloatingLabel && (
          <FloatingLabel
            size={size}
            value={value}
            ref={floatingLabelRef}
            disabled={disabled}
          >
            {label}
          </FloatingLabel>
        )}
      </Container>
      <SupportingTextContainer disabled={disabled}>
        <Paragraph
          size={ParagraphSize.sm}
          color={getSupportingTextColor()}
        >
          {supportingText}
        </Paragraph>
      </SupportingTextContainer>
    </div>
  );
}

export { Select };
