// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.276 10.6669L11.0373 3.90555L10.0947 2.96288L3.33333 9.72422V10.6669H4.276ZM4.82867 12.0002H2V9.17155L9.62333 1.54822C9.74835 1.42324 9.91789 1.35303 10.0947 1.35303C10.2714 1.35303 10.441 1.42324 10.566 1.54822L12.452 3.43422C12.577 3.55924 12.6472 3.72878 12.6472 3.90555C12.6472 4.08233 12.577 4.25187 12.452 4.37689L4.82867 12.0002ZM2 13.3336H14V14.6669H2V13.3336Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
