// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Component
import {
  Button,
  Container,
  Image,
  InfoContainer,
  TitleContainer,
} from './FilterBar.style';
import { FilterButtonProps, FilterBarProps } from './FilterBar.types';

// Layout
import { Paragraph, ParagraphSize, ParagraphWeight } from '../Paragraph';

// Types
import { Theme } from '../../../types/theme';

// Plugins
import { formatNumber } from '../../../plugins/general';

function FilterBar({
  className,
  filters,
  onClick,
  selected,
  testId,
}: FilterBarProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  return (
    <Container
      data-testid={testId}
      className={className}
    >
      {filters.map((item: FilterButtonProps) => (
        <Button
          key={item.key}
          selected={selected === item.key}
          onClick={() => onClick(item.key)}
        >
          {item.imgSrc && <Image src={item.imgSrc} alt={item.title} />}
          <InfoContainer>
            <TitleContainer>
              <Paragraph
                weight={ParagraphWeight.bold}
                size={ParagraphSize.sm}
                color={theme.color.filterBar.titleTextColor}
              >
                {item.title}
              </Paragraph>
            </TitleContainer>
            <Paragraph
              weight={ParagraphWeight.bold}
              size={ParagraphSize.sm}
              color={theme.color.filterBar.countTextColor}
            >
              {formatNumber(item.count, 0)}
            </Paragraph>
          </InfoContainer>
        </Button>
      ))}
    </Container>
  );
}

export { FilterBar };
