// Core
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../plugins/request';

// Store
import {
  Customer,
  User,
  getUser,
  setCustomer,
} from '../../../store/session.store';

// Media
import mockedCustomerLogo from '../../../media/images/logoSuperior.png';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { CardCustomer } from '../../../components/CardCustomer';
import {
  DashboardIndicator,
  DashboardIndicatorProps,
} from '../../../components/DashboardIndicator';
import { EmptyBox } from '../../../components/EmptyBox';
import { Button, ButtonSize } from '../../../layout/elements/Button';
import { Input } from '../../../layout/elements/Input';
import { Loader, LoaderSize } from '../../../layout/elements/Loader';
import { OptionsDropdownColor } from '../../../layout/elements/OptionsDropdown';
import { PageHeader } from '../../../layout/elements/PageHeader';
import { PaginationFooter } from '../../../layout/elements/PaginationFooter';
import { showToast, ToastType } from '../../../layout/elements/Toast';
import {
  Edit,
  ErrorWarningLine,
  MapPin,
  Profile,
  Search,
  TrashCan,
  View,
  Wifi,
} from '../../../layout/icons';

// Style
import {
  ButtonContainer,
  CardCustomerContainer,
  Container,
  FilterCustomersContainer,
  IndicatorsPanel,
  InputContainer,
  Layout,
  LoadingContainer,
  NewCustomerContainer,
  PageHeaderContainer,
} from './CustomersIndex.style';

// Views
import { CustomersCreate } from './Create';
import { CustomersDelete } from './Delete';
import { CustomersRead } from './Read';
import { UpdateCustomer } from './Update';

// Store
import { CustomerStats, UserType } from '../../../types/global';
import useCustomersStatsStore, { CustomersStatsDetails } from '../../../store/customersStats.store';

function CustomersIndex(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  // Get user
  const user: User | null = getUser();

  // Redirects if the user is not an admin
  if (!user || user.type !== UserType.admin) {
    navigate('/plant-management');
  }

  /* ***********************************************************************************************
  ******************************************* TYPES ************************************************
  *********************************************************************************************** */

  enum DrawerOperation {
    create = 'create',
    read = 'read',
    update = 'update',
    delete = 'delete',
  }

  interface BlankCustomer {
    name: string | null;
    id: string | null;
    status: string | null;
    domains: string[];
    stats: CustomerStats;
  }

  const blankCustomer: BlankCustomer = {
    name: null,
    id: null,
    status: null,
    domains: [],
    stats: {
      alarms: null,
      equipmentUnits: null,
      sites: null,
      users: null,
    },
  };

  interface CustomerState {
    currentPage: number;
    customers: Customer[];
    deleteConfirmationValue?: string | number | null;
    drawerOperation: DrawerOperation;
    globalStats: CustomerStats;
    isDrawerOpen: boolean;
    isLoading: boolean;
    pageSize: number | null;
    searchCustomersValue?: string | number | null;
    selectedCustomer: BlankCustomer | Customer;
  }

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  const initialState: CustomerState = {
    currentPage: 1,
    customers: [],
    deleteConfirmationValue: null,
    drawerOperation: DrawerOperation.create,
    globalStats: {
      alarms: null,
      equipmentUnits: null,
      sites: null,
      users: null,
    },
    isDrawerOpen: false,
    isLoading: false,
    pageSize: null,
    searchCustomersValue: null,
    selectedCustomer: {
      name: null,
      id: null,
      status: null,
      domains: [],
      stats: {
        alarms: null,
        equipmentUnits: null,
        sites: null,
        users: null,
      },
    },
  };

  const [state, setState] = useState<CustomerState>(initialState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  /* Open customer drawer */
  const openDrawer = (
    drawerOperation: DrawerOperation,
    selectedCustomer: BlankCustomer | Customer,
  ): void => {
    const customerWithStats = {
      ...selectedCustomer,
      stats: useCustomersStatsStore.getState().customersStats.find(
        (item: CustomersStatsDetails) => item.id === selectedCustomer.id,
      )?.stats || blankCustomer.stats,
    };

    setState({
      ...state,
      isDrawerOpen: true,
      drawerOperation,
      selectedCustomer: customerWithStats,
    });
  };

  /* Close customer drawer */
  const closeDrawer = (): void => {
    setState({
      ...state,
      isDrawerOpen: false,
      selectedCustomer: blankCustomer,
      deleteConfirmationValue: '',
    });
  };

  /* ********** Request **********  */

  /* Get the customers and their stats */
  const getCustomers = useCallback(async (): Promise<void> => {
    setState((prevState: CustomerState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const customersResponse: Customer[] = await requestVantageApi.customer({
        method: RequestMethod.GET,
        path: '/customer',
      });

      setState((prevState: CustomerState) => ({
        ...prevState,
        customers: customersResponse,
        isLoading: false,
      }));
    } catch (error: unknown) {
      setState((prevState: CustomerState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, []);

  /* Gets global stats */
  const getGlobalStats = useCallback(async (): Promise<void> => {
    const globalStatsResponse: CustomerStats = await requestVantageApi.customer({
      method: RequestMethod.GET,
      path: '/customer/stats',
      auth: true,
      handleErrors: true,
    });

    setState((prevState: CustomerState) => ({
      ...prevState,
      globalStats: globalStatsResponse,
    }));
  }, []);

  /* Set the customerId in the store and navigate to the plant management page */
  const handleCardClick = async (customer: Customer): Promise<void> => {
    setCustomer(customer);
    navigate('/plant-management');
  };

  /* Update the selected customer fields */
  const updateSelectedCustomerFields = (field: string, value: string | string[]): void => {
    setState({
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        [field]: value,
      },
    });
  };

  /* Create a new customer */
  const createCustomer = (): void => {
    requestVantageApi.customer({
      method: RequestMethod.POST,
      path: '/customer',
      auth: true,
      handleErrors: true,
      body: state.selectedCustomer,
    }).then((): void => {
      closeDrawer();
      getCustomers();
      showToast({
        text: t('view.customers.create.customerCreated', { customer: state.selectedCustomer.name }),
        type: ToastType.success,
      });
    }).catch((): void => {
      closeDrawer();
    });
  };

  /* Update customer data */
  const updateCustomer = (): void => {
    requestVantageApi.customer({
      method: RequestMethod.PUT,
      path: `/customer/${state.selectedCustomer.id}`,
      auth: true,
      handleErrors: true,
      body: state.selectedCustomer,
    }).then((): void => {
      closeDrawer();
      getCustomers();
      showToast({
        text: t('view.customers.update.customerUpdated', { customer: state.selectedCustomer.name }),
        type: ToastType.success,
      });
    }).catch((): void => {
      closeDrawer();
    });
  };

  /* Delete selected customer */
  const deleteCustomer = (): void => {
    requestVantageApi.customer({
      method: RequestMethod.DELETE,
      path: `/customer/${state.selectedCustomer.id}`,
      auth: true,
      handleErrors: true,
    }).then((): void => {
      closeDrawer();
      getCustomers();
      showToast({
        text: t('view.customers.delete.customerDeleted', { customer: state.selectedCustomer.name }),
        type: ToastType.success,
      });
    });
  };

  /* Change the page */
  const onPageChange = useCallback((page: number, newPageSize: number): void => {
    setState((prevState: CustomerState) => ({
      ...prevState,
      currentPage: page,
      pageSize: newPageSize,
    }));
  }, []);

  /* Filters the list of customers based on the search value */
  const filterCustomers = (): Customer[] => {
    const { customers, searchCustomersValue } = state;

    const filteredCustomers: Customer[] = customers.filter(
      (customer: Customer) => (
        searchCustomersValue
          ? customer.name.toLowerCase().includes(String(searchCustomersValue).toLowerCase())
          : true
      ),
    );

    return filteredCustomers;
  };

  /* Get the paginated customers */
  const paginatedCustomers: Customer[] = filterCustomers().slice(
    state.pageSize ? (state.currentPage - 1) * state.pageSize : 0,
    state.pageSize ? state.currentPage * state.pageSize : filterCustomers().length,
  );

  /* Check if there are customers */
  const hasCustomers: boolean = paginatedCustomers.length > 0;

  /* Dashboard Indicator Data */
  const indicatorsData: DashboardIndicatorProps[] = [
    {
      title: t('view.customers.globalStats.alarms.title'),
      icon: <ErrorWarningLine />,
      value: state.globalStats.alarms,
      critical: state.globalStats.alarms !== null && state.globalStats.alarms > 0,
      tooltipText: t('view.customers.globalStats.alarms.description'),
    },
    {
      title: t('view.customers.globalStats.equipmentUnits.title'),
      value: state.globalStats.equipmentUnits,
      icon: <Wifi />,
      tooltipText: t('view.customers.globalStats.equipmentUnits.description'),
    },
    {
      title: t('view.customers.globalStats.sites.title'),
      value: state.globalStats.sites,
      icon: <MapPin />,
      tooltipText: t('view.customers.globalStats.sites.description'),
    },
    {
      title: t('view.customers.globalStats.activeUsers.title'),
      value: state.globalStats.users,
      icon: <Profile />,
      tooltipText: t('view.customers.globalStats.activeUsers.description'),
    },
  ];

  /* ***********************************************************************************************
  ************************************* COMPONENT HANDLING *****************************************
  *********************************************************************************************** */

  /* Maps each drawer operation to its respective component to be mounted */
  const mountCustomerDrawers: Record<string, JSX.Element> = {
    create: (
      <CustomersCreate
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={closeDrawer}
        customer={state.selectedCustomer}
        onCreate={createCustomer}
        onFieldChange={updateSelectedCustomerFields}
      />
    ),
    read: (
      <CustomersRead
        customer={state.selectedCustomer}
        onCloseDrawer={closeDrawer}
        isDrawerOpen={state.isDrawerOpen}
        onOpenDelete={() => openDrawer(DrawerOperation.delete, state.selectedCustomer)}
        onOpenEdit={() => openDrawer(DrawerOperation.update, state.selectedCustomer)}
      />
    ),
    update: (
      <UpdateCustomer
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={closeDrawer}
        customer={state.selectedCustomer}
        onUpdate={updateCustomer}
        onFieldChange={(field: string, value: string | string[]) => setState(
          (prevState: CustomerState) => ({
            ...prevState,
            selectedCustomer: {
              ...prevState.selectedCustomer,
              [field]: value,
            },
          }),
        )}
      />
    ),
    delete: (
      <CustomersDelete
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={closeDrawer}
        onDelete={deleteCustomer}
        customer={state.selectedCustomer}
        deleteConfirmation={{
          value: state.deleteConfirmationValue,
          onChange: (value: string | number | null | undefined) => setState({
            ...state,
            deleteConfirmationValue: typeof value === 'string'
              ? value.toUpperCase()
              : value,
          }),
        }}
      />
    ),
  };

  /** ******* GET CUSTOMERS ******** */
  useEffect((): void => {
    getCustomers();
  }, [getCustomers]);

  /** ******* GET GLOBAL STATS ******** */
  useEffect((): void => {
    getGlobalStats();
  }, [getGlobalStats]);

  return (
    <AdminPanel>
      <Layout>
        {state.isLoading ? (
          <LoadingContainer>
            <Loader
              size={LoaderSize.xl}
              text={`${t('view.customers.loading')}`}
            />
          </LoadingContainer>
        ) : (
          <Container>
            <PageHeaderContainer>
              <PageHeader
                title={`${t('common.customers')}`}
                description={`${t('view.customers.description')}`}
              />
            </PageHeaderContainer>
            {!hasCustomers ? (
              <EmptyBox
                title={`${t('view.customers.emptyBox.title')}`}
                description={`${t('view.customers.emptyBox.description')}`}
              />
            ) : (
              <div>
                <IndicatorsPanel>
                  {indicatorsData.map((item: DashboardIndicatorProps) => (
                    <DashboardIndicator
                      testId="dashboard-indicator"
                      key={item.title}
                      title={item.title}
                      value={item.value}
                      tooltipText={item.tooltipText}
                      critical={item.critical}
                      icon={item.icon}
                    />
                  ))}
                </IndicatorsPanel>
                <InputContainer>
                  <NewCustomerContainer>
                    <FilterCustomersContainer>
                      <Input
                        testId="filter-customers"
                        placeholder={`${t('view.customers.searchCustomer')}`}
                        value={state.searchCustomersValue}
                        label={`${t('view.customers.searchCustomer')}`}
                        prefix={<Search />}
                        onChange={(value?: string | number | null) => {
                          setState((prevState: CustomerState) => ({
                            ...prevState,
                            searchCustomersValue: value,
                            currentPage: 1,
                          }));
                        }}
                      />
                    </FilterCustomersContainer>

                    <ButtonContainer>
                      <Button
                        testId="new-customer"
                        block={false}
                        size={ButtonSize.xs}
                        onClick={() => openDrawer(DrawerOperation.create, state.selectedCustomer)}
                      >
                        {t('view.customers.newCustomer')}
                      </Button>
                    </ButtonContainer>
                  </NewCustomerContainer>
                </InputContainer>

                <CardCustomerContainer>
                  {paginatedCustomers.map((customer: Customer) => (
                    <CardCustomer
                      idCustomer={customer.id}
                      imgSrc={mockedCustomerLogo}
                      key={customer.id}
                      name={customer.name}
                      onClick={() => handleCardClick(customer)}
                      testId={`customer-card-${customer.id}`}
                      options={[
                        {
                          text: `${t('common.view')}`,
                          icon: <View />, // Removed ts-ignore
                          color: OptionsDropdownColor.normal,
                          disabled: false,
                          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            e.stopPropagation();
                            openDrawer(DrawerOperation.read, customer);
                          },
                        },
                        {
                          text: `${t('common.edit')}`,
                          icon: <Edit />,
                          color: OptionsDropdownColor.normal,
                          disabled: false,
                          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            e.stopPropagation();
                            openDrawer(DrawerOperation.update, customer);
                          },
                        },
                      ]}
                      secondaryOptions={[
                        {
                          text: `${t('common.delete')}`,
                          icon: <TrashCan />,
                          color: OptionsDropdownColor.danger,
                          disabled: false,
                          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            e.stopPropagation();
                            openDrawer(DrawerOperation.delete, customer);
                          },
                        },
                      ]}
                    />
                  ))}
                </CardCustomerContainer>
              </div>
            )}
          </Container>
        )}
        {!state.isLoading && hasCustomers && (
          <PaginationFooter
            totalItems={filterCustomers().length}
            onPageChange={onPageChange}
            currentPage={state.currentPage}
          />
        )}
        {mountCustomerDrawers[state.drawerOperation]}
      </Layout>
    </AdminPanel>
  );
}

export { CustomersIndex };
